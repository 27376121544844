<template>
    <div>
        <Header :instanceInfo="instanceInfo" />
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="box" style="margin-top:10px; border: none!important;">
                        <div class="col-md-12" style="margin-bottom: 20px;">
                            <h3>Subscription Cancellation</h3>
                            <h4>Are you sure you want to cancel your subscription?</h4>
                            <button class="btn btn-default" @click="cancel">
                                Cancel Subscription
                            </button>
                            <p v-if="success" class="alert alert-success" style="margin-top: 15px;">Your subscription was successfully cancelled.<br />
                                Cancellation confirmation was sent to your email.
                            </p>
                            <p v-if="error" class="alert alert-danger" style="margin-top: 15px;">Your subscription can't be cancelled.<br />
                                Please <router-link to="/contact">contact our support</router-link> to help you cancel your subscription.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer :instance-info="instanceInfo" :company-name="companyName" v-bind="$attrs" />
    </div>
</template>

<script>
import { ref } from 'vue';
import { cancelSubscriptionByUuid } from '@/app/api/user';
import { useUrl } from '@/app/router';
import Header from '@/app/components/Header';
import Footer from '@/app/components/Footer';

export default {
    name: 'Activation',
    components: { Header, Footer },
    props: {
        instanceInfo: {
            type: Object,
            required: false,
        },
        companyName: {
          type: String,
          required: false,
        },
    },
  setup() {
    const { uuid } = useUrl();
    const success = ref(false);
    const error = ref(false);

    const cancel = () => {
        cancelSubscriptionByUuid(uuid.value)
      .then(() => {
        success.value = true;
      })
      .catch(() => {
        error.value = true;
      });
    }

    return {
        cancel,
        success,
      error
    };
  },
};
</script>
